import React from 'react';
import Microphone from "./Microphone";
import CameraButton from "./CameraButton";
import ScreenShareButton from "./ScreenShareButton";

function Navbar() {
    return (
        <div>
            <nav>
                <div className="p-3 p-lg-1 border-bottom bg-primary">
                    <div className="row align-items-center">
                        <div className="col-sm-12 col-12">
                            <Microphone /><CameraButton /><ScreenShareButton />
                        </div>

                    </div>
                </div>
            </nav>
        </div>
    );
}

export default Navbar;