import React, { useState } from "react";
import IconButton from "@mui/material/IconButton";
import ScreenShareIcon from "@mui/icons-material/ScreenShare";
import StopScreenShareIcon from "@mui/icons-material/StopScreenShare";


const constraints = {
    audio: false,
    video: true,
};

const ScreenShareButton = () => {
    const [screenShareActive, setScreenShareActive] = useState(true);
    const handleScreenShareToggle =  () => {

    };

    return (
        <IconButton onClick={handleScreenShareToggle} style={{ color: "white" }}>
            {screenShareActive ? <StopScreenShareIcon /> : <ScreenShareIcon />}
        </IconButton>
    );
};

export default ScreenShareButton;
