import React, {useEffect} from 'react';
import {Container} from "../style/RoomAndAvatarStyle";
import Navbar from "../components/videochat/Navbar";
import RoomPost from "../components/room/RoomPost";
import {connect} from "react-redux";
import {addRoom, fetchRooms} from "../redux/actions/roomActions";


const Room = (props) => {
    //const { theRooms } = props.rooms
    //console.log('theRooms...', theRooms);
    useEffect(() => {
        document.title = 'EDUGATE Chat - Chat room';
    }, []);


    useEffect(() =>{

        props.fetchRooms();
        console.log('fetching...', props.rooms);
    },[]);

    const handleBtnAddClick = () => {
        console.log('handleBtnAddClick');
    }
    return (
        <Container>

            <Navbar/>
            <div className="container">
                <RoomPost/>
                <h1>Liste des Salons <button onClick={handleBtnAddClick} className="btn btn-success d-none">Ajouter</button></h1>

            <div className="layout-wrapper d-lg-flex  d-flex justify-content-center avatars">




                    <table className="table table-bordered">
                        <thead>
                        <tr>
                            <th scope="col">#</th>
                            <th scope="col">First</th>
                            <th scope="col">Actions</th>
                        </tr>
                        </thead>
                        <tbody>

                        {
                            props.rooms.map(( room, index=0) => {

                                return (
                                    <tr key={room._id}>
                                        <td scope="row">{++index}</td>
                                        <td>{room.name}</td>
                                        <td><a className="btn btn-outline-primary" href="#">modifier</a> <a className="btn btn-outline-danger" href="#">Supprimer</a></td>
                                    </tr>
                                )
                            })
                        }
                        </tbody>
                    </table>


            </div>
            </div>
            <h1 className="text-center my-2 pt-sm-2 d-none">Choisissez un salon</h1>
            <div className="layout-wrapper d-lg-flex  d-flex justify-content-center d-none">
                <select name="room" id="" className="col-sm-3 col-md-2 my-2 d-none">
                    <option value="maths">Mathémathiques</option>
                </select>
            </div>

        </Container>
    );
};

const mapDispatchToProps = (dispatch) => {
    return {
        fetchRooms: () => dispatch(fetchRooms()),
    };
};

const mapStateToProps = state => ({
    rooms: state.rooms.rooms, // posts: => is from reducers.js:
    //newPost: state.posts.item //because single source of truth
})
export default connect(mapStateToProps, mapDispatchToProps)(Room);