import React, { useState, useEffect } from 'react';
import {Link, useNavigate} from "react-router-dom";
import { ToastContainer, toast} from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import axios from "axios";
import {registerRoute} from "../utils/APIRoutes";
import {validateRegisterForm} from "../utils/validator";

function Register(props) {

    const navigate = useNavigate();

    const [values, setValues] = useState({
        username: "",
        telephone: "",
        email: "",
        password: "",
        confirmPassword: "",
    });

    const toastOptions = {
        position: "top-center",
        autoClose: 8000,
        pauseOnHover: true,
        draggable: true,
        theme: "light"
    }

    useEffect(() => {
        document.title = 'EDUGATE Chat - Inscription';
    }, []);

    useEffect(() => {
        if (localStorage.getItem('chat-app-user')) {
            navigate("/");
        }
    }, []);

    const handleSubmit = async(event) => {
        event.preventDefault();


        if(handleValidation()) {
            console.log(registerRoute);
            const { password, confirmPassword, username, telephone, email } = values;
            const { data } = await axios.post(registerRoute, {
                username: username,
                password: password,
                email: email,
                confirmPassword: confirmPassword,
                telephone: telephone,
            });
            console.log(data);
            if(data.status === false) {
                toast.error(data.msg, toastOptions);
            }else if(data.status === true) {
                localStorage.setItem('chat-app-user', JSON.stringify(data.user));
                navigate("/");
            }

        }
    }

    const handleChange = (event) => {
        setValues({ ...values, [event.target.name]: event.target.value });
    };

    const handleValidation = () => {
        const { password, confirmPassword, username, telephone, email } = values;

        if (password !== confirmPassword) {
            toast.error(
                "Mot de passe et sa confirmation doivent être égaux.",
                toastOptions
            );
            return false;
        } else if (username.length < 3 || username.length > 20) {
            toast.error(
                "Pseudo est entre 3 et 20 caractères.",
                toastOptions
            );
            return false;
        } else if (password.length < 8) {
            toast.error(
                "Mot de passe d'au moins 8 caractères.",
                toastOptions
            );
            return false;
        } else if (email === "") {
            toast.error("Email est requis.", toastOptions);
            return false;
        }
        else if (telephone === "") {
            toast.error("Téléphone requis.", toastOptions);
            return false;
        }

        return validateRegisterForm({
            email,
            password,
            confirmPassword,
            username,
            telephone
        })

        //return true;
    };


    return (
        <>
        <div className="account-pages my-1 pt-sm-5">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-md-8 col-lg-6 col-xl-5">
                        <div className="text-center mb-4">
                            <h1 className="d-none">Edugate</h1>
                           <a href="index.html" className="auth-logo mb-5 d-block">
                                <img src="edugate_logo_nobg.png" alt="" className="img-responsive" />

                            </a>

                            <h4>Chat Inscription</h4>
                            <p className="text-muted mb-4">Créez votre compte chat Edugate maintenant.</p>

                        </div>

                        <div className="card">
                            <div className="card-body p-4">
                                <div className="p-3">
                                    <form action="index.html" onSubmit={(event)=>handleSubmit(event)}>

                                        <div className="mb-3">
                                            <label className="form-label">Email</label>
                                            <div className="input-group bg-soft-light rounded-3  mb-3">
                                                <span className="input-group-text text-muted" id="basic-addon5">
                                                    <i className="ri-mail-line"></i>
                                                </span>
                                                <input type="email"
                                                       className="form-control form-control-lg bg-soft-light border-light"
                                                       placeholder="Saisir votre Email" aria-label="Enter Email"
                                                       name="email"
                                                       aria-describedby="basic-addon5" onChange={(e)=>handleChange(e)} />

                                            </div>
                                        </div>

                                        <div className="mb-3">
                                            <label className="form-label">Pseudo</label>
                                            <div className="input-group bg-soft-light mb-3 rounded-3">
                                                <span className="input-group-text border-light text-muted"
                                                      id="basic-addon6">
                                                    <i className="ri-user-2-line"></i>
                                                </span>
                                                <input type="text"
                                                       className="form-control form-control-lg bg-soft-light border-light"
                                                       placeholder="Saisir votre pseudo chat" aria-label="Enter Username"
                                                       aria-describedby="basic-addon6"
                                                       name="username"
                                                       onChange={(e)=>handleChange(e)} />

                                            </div>
                                        </div>

                                        <div className="mb-3">
                                            <label className="form-label">Téléphone</label>
                                            <div className="input-group bg-soft-light mb-3 rounded-3">
                                                <span className="input-group-text border-light text-muted"
                                                      id="basic-addon6">
                                                    <i className="ri-phone-line"></i>
                                                </span>
                                                <input type="text"
                                                       className="form-control form-control-lg bg-soft-light border-light"
                                                       placeholder="Saisir votre numéro mobile(10 chiffres)" aria-label="telephone"
                                                       aria-describedby="basic-addon6"
                                                       name="telephone"
                                                       onChange={(e)=>handleChange(e)} />

                                            </div>
                                        </div>

                                        <div className="mb-4">
                                            <label className="form-label">Mot de passe</label>
                                            <div className="input-group bg-soft-light mb-3 rounded-3">
                                                <span className="input-group-text border-light text-muted"
                                                      id="basic-addon7">
                                                    <i className="ri-lock-2-line"></i>
                                                </span>
                                                <input type="password"
                                                       className="form-control form-control-lg bg-soft-light border-light"
                                                       placeholder="Saisir votre mot de passe" aria-label="Enter Password"
                                                       aria-describedby="basic-addon7"
                                                       name="password"
                                                       onChange={(e)=>handleChange(e)} />

                                            </div>
                                        </div>
                                        <div className="mb-4">
                                            <label className="form-label">Confirmation de mot de passe</label>
                                            <div className="input-group bg-soft-light mb-3 rounded-3">
                                                <span className="input-group-text border-light text-muted"
                                                      id="basic-addon7">
                                                    <i className="ri-lock-2-line"></i>
                                                </span>
                                                <input type="password"
                                                       className="form-control form-control-lg bg-soft-light border-light"
                                                       placeholder="Saisir votre mot de passe de nouveau" aria-label="Enter Password"
                                                       aria-describedby="basic-addon7"
                                                       name="confirmPassword"
                                                       onChange={(e)=>handleChange(e)}/>

                                            </div>
                                        </div>


                                        <div className="d-grid">
                                            <button className="btn btn-primary waves-effect waves-light"
                                                    type="submit">S'inscrire
                                            </button>
                                        </div>

                                        <div className="mt-4 text-center d-none">
                                            <p className="text-muted mb-0">By registering you agree to the Edugate Msg <a
                                                href="#" className="text-primary">Terms of Use</a></p>
                                        </div>

                                    </form>
                                </div>
                            </div>
                        </div>

                        <div className="mt-5 text-center">
                            <p>Vous avez un compte? <Link to="/login"> Se connecter </Link></p>
                            <p>© {new Date().getFullYear()} Edugate. </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
            <ToastContainer/>
        </>
);
}

export default Register;