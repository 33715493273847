import {ADD_ROOM, ADD_ROOM_SUCCESS, FETCH_ROOM} from "../actions/roomActions";

const initialData = {
    rooms: [],
    room: {},

}

const roomReducer = (state=initialData, action) => {
    switch (action.type) {

        case ADD_ROOM:
            return {
                ...state,
                rooms:[...state.rooms, action.payload]
            }
            break;
        case ADD_ROOM_SUCCESS:
            return {
                ...state,
                rooms:[...state.rooms, action.payload]
            }
            break;
        case FETCH_ROOM:
            console.log(FETCH_ROOM);
            console.log(action.payload);
            console.log(typeof action.payload);
            return {
                ...state,
                rooms: action.payload
            }
            break;
        default:
            return state;

    }
}

export default roomReducer;