//export let host = "http://localhost:7777";
export let host = 'https://chatserver.edugate.ci';

export const registerRoute = `${host}/api/auth/register`;
export const loginRoute = `${host}/api/auth/login`;
export const avatarRoute = `${host}/api/auth/avatar`;
export const allUsersRoute = `${host}/api/auth/allusers`;
export const logoutRoute = `${host}/api/auth/logout`;
export const sendMessageRoute = `${host}/api/message/addmessage`;
export const getAllMessageRoute = `${host}/api/message/getallmessage`;
export const addRoomRoute = `${host}/api/room/addroom`;
export const getAllRoomRoute = `${host}/api/room/getallroom`;
/*must corresponde to app.use("/api/message", messageRoute); server side
  messageRoute. Ex here router.post("/addmessage", addMessage);
 */
