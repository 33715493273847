import React, {useState} from 'react';

export function useAuth() {
    const [currentUser, setCurrentUser] = useState(null);

    const signOut = () => {
        localStorage.removeItem('chat-app-user');
        setCurrentUser(null);
    };
    return {
        signOut,
    }
}
