import React from 'react';

const Welcome = ({ currentUser }) => {
    return(
        <div className="w-100 overflow-hidden position-relative my-5">
            <div className="layout-wrapper d-lg-flex d-flex justify-content-center">
                <h1 className="align-middle">Bienvenue dans le chat.</h1>

            </div>
        </div>
    )
}

export default Welcome;