import io from 'socket.io-client';
import {
  setPendingFriendsInvitations,
  setFriends,
  setOnlineUsers,
} from "../redux/actions/friendsActions";
import store from "../redux/store";
import * as roomHandler from "./roomHandler";
import * as webRTCHandler from "./webRTCHandler";

let socket = null;
export let sharedSocket = null;

export const  setSocketServerInstance = (socketInstance) => {
    sharedSocket = socketInstance;
}

export const  getSocketServerInstance = () => {
    return sharedSocket;
}

export const connectWithSocketServer = (currentUser) => {
    socket = io('http://localhost:7777', {
        auth: {
            token: currentUser.token
        }
    });

    socket.on('connect', () => {
        console.log('connect successfully as',currentUser, socket.id);
    });

    setSocketServerInstance(socket);

    socket.on("friends-list", (data) => {
    const { friends } = data;
    console.log('on friends-list', typeof JSON.parse(friends));
    store.dispatch(setFriends(friends));
  });

    socket.on('online-users', (data) => {
        const { onlineUsers } = data;
        console.log('online users update',data);
        store.dispatch(setOnlineUsers(onlineUsers));
    });


    socket.on("room-create", (data) => {
        roomHandler.newRoomCreated(data);
    });

    socket.on("active-rooms", (data) => {
        roomHandler.updateActiveRooms(data);
    });


};

export const sendDirectMessage = (data) => {
  console.log(data);
  socket.emit("direct-message", data);
  socket.emit("send-msg", data);
};

export const receiveDirectMessage = (data) => {
  console.log(receiveDirectMessage);
  socket.on("msg-recieve", (data) => {

  });

};

export const emitJoinRoom = (room, id) => {
    console.log('emitJoinRoom', room);
    socket.emit('join', room, id);

};

export const createNewRoom = () => {
    socket.emit("room-create");
};

export const joinRoom = (data) => {
    socket.emit("room-join", data);
};

export const leaveRoom = (data) => {
    socket.emit("room-leave", data);
};

export const signalPeerData = (data) => {
    socket.emit("conn-signal", data);
};