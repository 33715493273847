import React from 'react';
import { Link, useNavigate } from "react-router-dom";
import styled from "styled-components";
import axios from "axios";
import { logoutRoute } from "../utils/APIRoutes";


function Logout(props) {
    const navigate = useNavigate();

    const handleClick = async (event) => {
        console.log('handleClick')
        event.preventDefault();
        const id = await JSON.parse(
            localStorage.getItem('chat-app-user')
        )._id;
        /*const data = await axios.get(`${logoutRoute}/${id}`);
        if (data.status === 200) {*/
            localStorage.clear();
            navigate("/login");
        //}
    };


    return (
        <>
            <li className="list-inline-item">
                <Link className="dropdown-item" onClick={handleClick}>Log out <i
                    className="ri-logout-circle-r-line float-end text-muted"></i></Link>
            </li>
        </>
    );
}

export default Logout;