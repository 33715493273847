import React, { useState } from 'react';
import {Container} from "../../style/RoomAndAvatarStyle";
import Navbar from "../videochat/Navbar";
import {connect} from "react-redux";
import {addRoom, fetchRooms} from "../../redux/actions/roomActions";

function RoomPost(props) {
    let [room, setRoom] = useState({});

    function handleRoomChange(event) {

        console.log(event.target.value, {name: event.target.value});
        setRoom({name: event.target.value});
    }

    const handleRoomSubmit = (event) => {
        event.preventDefault();
        props.handleSubmit(room);
    }

    return (
        <Container>

            <h1 className="mx-auto">Ajout de salons</h1>
            <div className="justify-content-center row">
                <div className="col-md-8 offset-md-4">
                    <form className="form-horizontal mx-auto" onSubmit={handleRoomSubmit}>
                        <div className="form-group row">
                            <label htmlFor="salon" className="col-sm-2 col-form-label">Salon</label>
                            <div className="col-sm-10">
                                <input type="text" className="form-control" onChange={handleRoomChange} id="salon" placeholder="Salon"/>
                            </div>
                        </div>

                        <div className="form-group row d-flex justify-content-center mt-1">
                            <div className="col-sm-8 offset-md-4">
                                <button type="submit" className="btn btn-primary">Ajouter</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
            <h1 className="text-center my-2 pt-sm-2 d-none">Choisissez un salon</h1>
            <div className="layout-wrapper d-lg-flex  d-flex justify-content-center d-none">
                <select name="room" id="" className="col-sm-3 col-md-2 my-2 d-none">
                    <option value="maths">Mathémathiques</option>
                </select>
            </div>

        </Container>

    );
}
const mapStateToProps = state => ({
    rooms: state.rooms // posts: => is from reducers.js:
});


const mapDispatchToProps = (dispatch) => {
    return {
        handleSubmit: (room) => dispatch(addRoom(room)),

    };
};

export default connect(mapStateToProps, mapDispatchToProps)(RoomPost);