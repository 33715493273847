import React, {useEffect, useState} from 'react';
import {connect} from "react-redux";
import {fetchRooms} from "../../redux/actions/roomActions";
import {Link, useNavigate} from "react-router-dom";
import VideoChat from "../../pages/VideoChat";


function Salon(props) {

    const chatvideohost = "https://webrtc.edugate.ci";

    const navigate = useNavigate();

    const [currentUserName, setCurrentUserName] = useState(undefined);
    const [currentUserImage, setCurrentUserImage] = useState(undefined);
    const [currentUser, setCurrentUser] = useState(null);
    const [currentUserId, setCurrentUserId] = useState("");
    const [isLoaded, setIsLoaded] = useState(false);

    useEffect(() =>{

        props.fetchRooms();
        console.log('fetching...', props.rooms);
        console.log('fetching...currentUser', props.currentUser);
    },[]);

    useEffect(() => {
        async function currentUser() {
            const data = await JSON.parse(
                localStorage.getItem('chat-app-user')
            );
            if(data) {
                setCurrentUserName(data.username);
                setCurrentUserImage(data.avatarImage);
                setCurrentUserId(data._id);
            }


        }
        currentUser();
    }, []);


    useEffect(  () => {

        async function fetchLocalStorage(){
            if (!localStorage.getItem('chat-app-user'))
                navigate("/login");
            else {
                setCurrentUser(
                    await JSON.parse(
                        localStorage.getItem('chat-app-user')
                    )
                );
                setIsLoaded(true);

            }
        }
        fetchLocalStorage();

    }, []);


    return (
        <div className="p-4 chat-message-list chat-group-list" data-simplebar>


            <ul className="list-unstyled chat-list">
                {
                    props.rooms.map(( room, index=0) => {
                        return(
                    <li key={room._id}>
                        <a href="#">
                            <div className="d-flex align-items-center">
                                <div className="chat-user-img me-3 ms-0">
                                    <div className="avatar-xs">
                                                            <span
                                                                className="avatar-title rounded-circle bg-soft-primary text-primary">
                                                                M
                                                            </span>
                                    </div>
                                </div>
                                <div className="flex-1 overflow-hidden">
                                    <h5 className="text-truncate font-size-14 mb-0">
                                        <a href={`${chatvideohost}/index.html?room=${room.name}&uuid=${currentUserId}&pseudo=${currentUserName}`} target="_blank">#{room.name}</a>
                                    </h5>
                                </div>
                            </div>
                        </a>
                    </li>
                        )
                    })
                }

            </ul>
        </div>
    );
}

const mapDispatchToProps = (dispatch) => {
    return {
        fetchRooms: () => dispatch(fetchRooms()),
    };
};

const mapStateToProps = state => ({
    rooms: state.rooms.rooms, // posts: => is from reducers.js:
    onlineUsers: state.onlineUsers,
    //newPost: state.posts.item //because single source of truth
})

export default connect(mapStateToProps, mapDispatchToProps)(Salon);