import React, { useState, useEffect } from 'react';
import {Link, useNavigate} from "react-router-dom";
import { ToastContainer, toast} from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import axios from "axios";
import {loginRoute} from "../utils/APIRoutes";
import {validateLoginForm, validateRegisterForm} from "../utils/validator";

function Login(props) {

    const navigate = useNavigate();

    const [values, setValues] = useState({
        telephone: "",
        password: "",
    });

    const toastOptions = {
        position: "top-center",
        autoClose: 8000,
        pauseOnHover: true,
        draggable: true,
        theme: "light"
    }

    useEffect(() => {
        document.title = 'EDUGATE Chat - Login';
    }, []);

    useEffect(() => {
        if (localStorage.getItem('chat-app-user')) {
            navigate("/");
        }
    }, []);

    const handleSubmit = async(event) => {
        event.preventDefault();


        if(handleValidation()) {
            console.log(loginRoute);
            const { password,  telephone, } = values;
            const { data } = await axios.post(loginRoute, {
                password: password,
                telephone: telephone,
            });
            console.log(data);
            if(data.status === false) {
                toast.error(data.msg, toastOptions);
            }else if(data.status === true) {
                localStorage.setItem('chat-app-user', JSON.stringify(data.user));
                navigate("/");
            }

        }
    }

    const handleChange = (event) => {
        setValues({ ...values, [event.target.name]: event.target.value });
    };

    const handleValidation = () => {
        const { password, telephone } = values;

        if (telephone.length === 0) {
            toast.error(
                "login requis",
                toastOptions
            );
            return false;
        } else if (password.length  === 0) {
            toast.error(
                "Mot de passe requis.",
                toastOptions
            );
            return false;
        }

        return validateLoginForm({
            telephone,
            password
        })

        //return true;
    };


    return (
        <>
            <div className="account-pages my-5 pt-sm-5">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-md-8 col-lg-6 col-xl-5">
                            <div className="text-center mb-4">
                                <h1 className="d-none">Edugate</h1>
                                <a href="index.html" className="auth-logo mb-5 d-block">
                                    <img src="edugate_logo_nobg.png" alt="" className="img-responsive" />

                                </a>

                                <h4>Login</h4>
                                <p className="text-muted mb-4">Edugate Chat login.</p>

                            </div>

                            <div className="card">
                                <div className="card-body p-4">
                                    <div className="p-3">
                                        <form onSubmit={(event)=>handleSubmit(event)}>

                                            <div className="mb-3">
                                                <label className="form-label">Téléphone</label>
                                                <div className="input-group bg-soft-light mb-3 rounded-3">
                                                <span className="input-group-text border-light text-muted"
                                                      id="basic-addon6">
                                                    <i className="ri-phone-line"></i>
                                                </span>
                                                    <input type="text"
                                                           className="form-control form-control-lg bg-soft-light border-light"
                                                           aria-label="telephone"
                                                           placeholder="Saisir votre numéro mobile(10 chiffres)"
                                                           aria-describedby="basic-addon6"
                                                           name="telephone"
                                                           onChange={(e)=>handleChange(e)} />

                                                </div>
                                            </div>

                                            <div className="mb-4">
                                                <label className="form-label">Mot de passe</label>
                                                <div className="input-group bg-soft-light mb-3 rounded-3">
                                                <span className="input-group-text border-light text-muted"
                                                      id="basic-addon7">
                                                    <i className="ri-lock-2-line"></i>
                                                </span>
                                                    <input type="password"
                                                           className="form-control form-control-lg bg-soft-light border-light"
                                                           placeholder="Saisir votre mot de passe" aria-label="Enter Password"
                                                           aria-describedby="basic-addon7"
                                                           name="password"
                                                           onChange={(e)=>handleChange(e)} />

                                                </div>
                                            </div>

                                            <div className="d-grid">
                                                <button className="btn btn-primary waves-effect waves-light"
                                                        type="submit">Se connecter
                                                </button>
                                            </div>

                                            <div className="mt-4 text-center">
                                                <p className="text-muted mb-0">Pas de compte? <a
                                                    href="/register" className="text-primary">Créer un compte</a></p>
                                            </div>

                                        </form>
                                    </div>
                                </div>
                            </div>

                            <div className="mt-5 text-center d-none">
                                <p>Vous avez un compte? <Link to="/login"> Se connecter </Link></p>
                                <p>© {new Date().getFullYear()} Edugate. </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <ToastContainer/>
        </>
    );
}

export default Login;