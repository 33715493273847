
export const validateLoginForm = ({ telephone, password }) => {
    const isTelephoneValid = validateTelephone(telephone);
    const isPasswordValid = validatePassword(password);

    return isTelephoneValid && isPasswordValid;
};


export const validateRegisterForm = ({ email, password, confirmPassword, username, telephone }) => {
    return (
        validateEmail(email) &&
        validatePassword(password) &&
        validatePassword(confirmPassword) &&
        validateUsername(username) &&
        validateTelephone(telephone)
    );
};

const validatePassword = (password) => {
    return password.length >= 8 && password.length < 15;
};

export const validateEmail = (email) => {
    const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    return emailPattern.test(email);
};

const validateUsername = (username) => {
    return username.length > 2 && username.length <= 20;
};
const isNumeric = (telephone) => (typeof(telephone) === 'number') || (typeof(telephone) === "string") && (telephone.trim() !== '') && (!isNaN(telephone));

const validateTelephone = (telephone) => {
    return telephone.length >= 10 && telephone.length < 20  &&  isNumeric(telephone);
};
