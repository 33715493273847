import React, {useEffect, useState} from 'react';
import styled from "styled-components";
import { Container } from "../style/ContactsStyle";
import { chatTypes, getActions } from "../redux/actions/chatActions";

import { connect } from "react-redux";

//friends here is all chat users
const checkOnlineUsers = (friends = [], onlineUsers = []) => {

    console.log('onlineUsers from contacts components', onlineUsers);
    console.log('friends from contacts components', friends);
  friends.forEach((f) => {
    const isUserOnline = onlineUsers.find((user) => user.userId === f._id);
    f.isOnline = isUserOnline ? true : false;
  });

  return friends;
};

function Contacts({ contacts, id, username, onlineUsers, changeChat }) {

    const [currentUserName, setCurrentUserName] = useState(undefined);
    const [currentUserImage, setCurrentUserImage] = useState(undefined);
    const [currentSelected, setCurrentSelected] = useState(undefined);

    //console.log(contacts);

    useEffect(() => {
        async function currentUser() {
            const data = await JSON.parse(
                localStorage.getItem('chat-app-user')
            );
            if(data) {
                setCurrentUserName(data.username);
                setCurrentUserImage(data.avatarImage);
            }


        }
        currentUser();
    }, []);

    const changeCurrentChat = (index, contact) => {
        setCurrentSelected(index);
        console.log(contact);
        changeChat(contact);
    }

    const handleChooseActiveConversation = () => {
        this.props.setChosenChatDetails({ id: id, name: username }, chatTypes.DIRECT);
    };

    return (
        <>
            <Container>
            <div className="px-2">
                <div className="d-flex m-3">
                    <div className="chat-user-img online align-self-center me-3 ms-0">
                        <img src={`data:image/svg+xml;base64,${currentUserImage}`}
                             className="rounded-circle avatar-xs" alt=""/>
                        <span className="user-status"></span>
                    </div>

                    <div className="flex-1 overflow-hidden">
                        <h5 className="text-truncate font-size-15 mb-1">{currentUserName}</h5>
                        <p className="chat-user-message text-truncate mb-0 d-none">Hey! there
                            I'm available</p>
                    </div>
                    <div className="font-size-11 d-none">05 min</div>
                </div>
                <h5 className="mb-3 px-3 font-size-16 d-none">Recent</h5>
                <h5 className="mb-3 px-3 font-size-16">Utilisateurs</h5>

                <div className="chat-message-list" data-simplebar>

                    <ul className="list-unstyled chat-list chat-user-list">
                        {
                            checkOnlineUsers(contacts, onlineUsers)
                            .map((contact, index) => {
                            //contacts.map((contact, index) => {
                                //console.log('ici',contact._id)
                                //console.log('call api to see if this user has receive msg',contact._id)
                                return(
                                    <li key={contact._id} className={`contact ${
                                        index === currentSelected ? "selected active" : ""
                                    }`}  onClick={() => changeCurrentChat(index, contact)} >
                                        <a href="#">
                                            <div className="d-flex">
                                                <div className={`chat-user-img ${
                                        contact.isOnline === true ? "online": "away"} align-self-center me-3 ms-0`}>
                                                    <img src={`data:image/svg+xml;base64,${contact.avatarImage}`}
                                                         className="rounded-circle avatar-xs" alt=""/>
                                                    <span className="user-status"></span>
                                                </div>

                                                <div className="flex-1 overflow-hidden">
                                                    <h5 className="text-truncate font-size-15 mb-1">{contact.username}</h5>
                                                    <p className="chat-user-message text-truncate mb-0 d-none">Hey! there
                                                        I'm available</p>
                                                </div>
                                                <div className="font-size-11 d-none">05 min</div>
                                            </div>
                                        </a>
                                    </li>
                                );
                            })
                        }
                        {/*

                        <li>
                            <a href="#">
                                <div className="d-flex">
                                    <div className="chat-user-img online align-self-center me-3 ms-0">
                                        <img src="assets/images/users/avatar-2.jpg"
                                             className="rounded-circle avatar-xs" alt=""/>
                                        <span className="user-status"></span>
                                    </div>

                                    <div className="flex-1 overflow-hidden">
                                        <h5 className="text-truncate font-size-15 mb-1">Patrick
                                            Hendricks</h5>
                                        <p className="chat-user-message text-truncate mb-0">Hey! there
                                            I'm available</p>
                                    </div>
                                    <div className="font-size-11">05 min</div>
                                </div>
                            </a>
                        </li>


                        <li className="unread">
                            <a href="#">
                                <div className="d-flex">
                                    <div className="chat-user-img away align-self-center me-3 ms-0">
                                        <img src="assets/images/users/avatar-3.jpg"
                                             className="rounded-circle avatar-xs" alt=""/>
                                        <span className="user-status"></span>
                                    </div>
                                    <div className="flex-1 overflow-hidden">
                                        <h5 className="text-truncate font-size-15 mb-1">Mark Messer</h5>
                                        <p className="chat-user-message text-truncate mb-0"><i
                                            className="ri-image-fill align-middle me-1 ms-0"></i> Images
                                        </p>
                                    </div>
                                    <div className="font-size-11">12 min</div>
                                    <div className="unread-message">
                                        <span className="badge badge-soft-danger rounded-pill">02</span>
                                    </div>
                                </div>
                            </a>
                        </li>

                        <li>
                            <a href="#">
                                <div className="d-flex">
                                    <div className="chat-user-img align-self-center me-3 ms-0">
                                        <div className="avatar-xs">
                                                            <span
                                                                className="avatar-title rounded-circle bg-soft-primary text-primary">
                                                                G
                                                            </span>
                                        </div>
                                    </div>
                                    <div className="flex-1 overflow-hidden">
                                        <h5 className="text-truncate font-size-15 mb-1">General</h5>
                                        <p className="chat-user-message text-truncate mb-0">This theme
                                            is awesome!</p>
                                    </div>
                                    <div className="font-size-11">20 min</div>
                                </div>
                            </a>
                        </li>

                        <li className="active">
                            <a href="#">
                                <div className="d-flex">
                                    <div className="chat-user-img online align-self-center me-3 ms-0">
                                        <img src="assets/images/users/avatar-4.jpg"
                                             className="rounded-circle avatar-xs" alt=""/>
                                        <span className="user-status"></span>
                                    </div>
                                    <div className="flex-1 overflow-hidden">
                                        <h5 className="text-truncate font-size-15 mb-1">Doris Brown</h5>
                                        <p className="chat-user-message text-truncate mb-0">Nice to meet
                                            you</p>
                                    </div>
                                    <div className="font-size-11">10:12 AM</div>
                                </div>
                            </a>
                        </li>
                        <li className="unread">
                            <a href="#">
                                <div className="d-flex">
                                    <div className="chat-user-img align-self-center me-3 ms-0">
                                        <div className="avatar-xs">
                                                            <span
                                                                className="avatar-title rounded-circle bg-soft-primary text-primary">
                                                                D
                                                            </span>
                                        </div>
                                    </div>
                                    <div className="flex-1 overflow-hidden">
                                        <h5 className="text-truncate font-size-15 mb-1">Designer</h5>
                                        <p className="chat-user-message text-truncate mb-0">Next meeting
                                            tomorrow 10.00AM</p>
                                    </div>
                                    <div className="font-size-11">12:01 PM</div>
                                    <div className="unread-message">
                                        <span className="badge badge-soft-danger rounded-pill">01</span>
                                    </div>
                                </div>
                            </a>
                        </li>
                        <li>
                            <a href="#">
                                <div className="d-flex">
                                    <div className="chat-user-img away align-self-center me-3 ms-0">
                                        <img src="assets/images/users/avatar-6.jpg"
                                             className="rounded-circle avatar-xs" alt=""/>
                                        <span className="user-status"></span>
                                    </div>
                                    <div className="flex-1 overflow-hidden">
                                        <h5 className="text-truncate font-size-15 mb-1">Steve
                                            Walker</h5>
                                        <p className="chat-user-message text-truncate mb-0"><i
                                            className="ri-file-text-fill align-middle me-1 ms-0"></i> Admin-A.zip
                                        </p>
                                    </div>
                                    <div className="font-size-11">03:20 PM</div>
                                </div>
                            </a>
                        </li>
                        <li className="typing">
                            <a href="#">
                                <div className="d-flex">
                                    <div className="chat-user-img align-self-center online me-3 ms-0">
                                        <div className="avatar-xs">
                                                            <span
                                                                className="avatar-title rounded-circle bg-soft-primary text-primary">
                                                                A
                                                            </span>
                                        </div>
                                        <span className="user-status"></span>
                                    </div>
                                    <div className="flex-1 overflow-hidden">
                                        <h5 className="text-truncate font-size-15 mb-1">Albert
                                            Rodarte</h5>
                                        <p className="chat-user-message text-truncate mb-0">typing<span
                                            className="animate-typing">
                                                            <span className="dot"></span>
                                                            <span className="dot"></span>
                                                            <span className="dot"></span>
                                                        </span></p>
                                    </div>
                                    <div className="font-size-11">04:56 PM</div>
                                </div>
                            </a>
                        </li>

                        <li>
                            <a href="#">
                                <div className="d-flex">
                                    <div className="chat-user-img align-self-center online me-3 ms-0">
                                        <div className="avatar-xs">
                                                            <span
                                                                className="avatar-title rounded-circle bg-soft-primary text-primary">
                                                                M
                                                            </span>
                                        </div>
                                        <span className="user-status"></span>
                                    </div>
                                    <div className="flex-1 overflow-hidden">
                                        <h5 className="text-truncate font-size-15 mb-1">Mirta
                                            George</h5>
                                        <p className="chat-user-message text-truncate mb-0">Yeah
                                            everything is fine</p>
                                    </div>
                                    <div className="font-size-11">12/07</div>
                                </div>
                            </a>
                        </li>
                        <li>
                            <a href="#">
                                <div className="d-flex">
                                    <div className="chat-user-img away align-self-center me-3 ms-0">
                                        <img src="assets/images/users/avatar-7.jpg"
                                             className="rounded-circle avatar-xs" alt=""/>
                                        <span className="user-status"></span>
                                    </div>
                                    <div className="flex-1 overflow-hidden">
                                        <h5 className="text-truncate font-size-15 mb-1">Paul Haynes</h5>
                                        <p className="chat-user-message text-truncate mb-0">Good
                                            morning</p>
                                    </div>
                                    <div className="font-size-11">12/07</div>
                                </div>
                            </a>
                        </li>
                        <li>
                            <a href="#">
                                <div className="d-flex">
                                    <div className="chat-user-img align-self-center online me-3 ms-0">
                                        <div className="avatar-xs">
                                                            <span
                                                                className="avatar-title rounded-circle bg-soft-primary text-primary">
                                                                J
                                                            </span>
                                        </div>
                                        <span className="user-status"></span>
                                    </div>
                                    <div className="flex-1 overflow-hidden">
                                        <h5 className="text-truncate font-size-15 mb-1">Jonathan
                                            Miller</h5>
                                        <p className="chat-user-message text-truncate mb-0">Hi, How are
                                            you?</p>
                                    </div>
                                    <div className="font-size-11">12/07</div>
                                </div>
                            </a>
                        </li>
                        <li>
                            <a href="#">
                                <div className="d-flex">
                                    <div className="chat-user-img away align-self-center me-3 ms-0">
                                        <img src="assets/images/users/avatar-8.jpg"
                                             className="rounded-circle avatar-xs" alt=""/>
                                        <span className="user-status"></span>
                                    </div>
                                    <div className="flex-1 overflow-hidden">
                                        <h5 className="text-truncate font-size-15 mb-1">Ossie
                                            Wilson</h5>
                                        <p className="chat-user-message text-truncate mb-0">I've
                                            finished it! See you so</p>
                                    </div>
                                    <div className="font-size-11">11/07</div>
                                </div>
                            </a>
                        </li>
                        <li>
                            <a href="#">
                                <div className="d-flex">
                                    <div className="chat-user-img align-self-center online me-3 ms-0">
                                        <div className="avatar-xs">
                                                            <span
                                                                className="avatar-title rounded-circle bg-soft-primary text-primary">
                                                                S
                                                            </span>
                                        </div>
                                        <span className="user-status"></span>
                                    </div>
                                    <div className="flex-1 overflow-hidden">
                                        <h5 className="text-truncate font-size-15 mb-1">Sara Muller</h5>
                                        <p className="chat-user-message text-truncate mb-0">Wow that's
                                            great</p>
                                    </div>
                                    <div className="font-size-11">11/07</div>
                                </div>
                            </a>
                        </li>
                       */}
                    </ul>

                </div>
            </div>
            </Container>
        </>
    );
}

const mapStoreStateToProps = ({ friends }) => {
  return {
    ...friends,
  };
};

const mapActionsToProps = (dispatch) => {
  return {
    ...getActions(dispatch),
  };
};

export default connect(mapStoreStateToProps, mapActionsToProps)(Contacts);
