import React, {useEffect, useRef, useState} from 'react';
import {useNavigate, useLocation } from "react-router-dom";
import {Container} from "../style/RoomAndAvatarStyle";
import Navbar from "../components/videochat/Navbar";
import Video from "../components/videochat/Video";
import createPeerConnection from "../realtimecommunication/peerConnections";
import {
    connectWithSocketServer,
    emitJoinRoom,
    getSocketServerInstance
} from "../realtimecommunication/socketConnection";
import io from 'socket.io-client';
import {Peer} from "peerjs";

function VideoChat() {
    let location = useLocation();
    let query = new URLSearchParams(location.search);
    let roomId = query.get("salon")
    //console.log('roomId', roomId);

    const socket = io('/');

    //const socket = useRef();
    const [currentUser, setCurrentUser] = useState(undefined);
    const [isLoaded, setIsLoaded] = useState(false);
    const [streams, setStreams] = useState([]);
    const [newStream, setNewStream] = useState(null);


    const navigate = useNavigate();

    const addVideoStream = (stream) => {
        const filteredStreams = streams.filter(s => s.id !== stream.id);
        setStreams([...filteredStreams, stream]);
    };

    /*const sock = io('/');

    setSocket(sock);


    const myPeer = new Peer(undefined, {
        host: '/',
        port: '3000',
        path: '/peerjs/myapp'
    });*/


    useEffect(  () => {

        async function fetchLocalStorage(){
            if (!localStorage.getItem('chat-app-user'))
                navigate("/login");
            else {
                setCurrentUser(
                    await JSON.parse(
                        localStorage.getItem('chat-app-user')
                    )
                );
                setIsLoaded(true);

            }
        }
        fetchLocalStorage()

    }, []);

    const connectToNewUser = (userId, stream, peer) => {
        console.log('connecting to new user', { userId, stream });
        const call = peer.call(userId, stream);
        call.on('stream', (userVideoStream) => {
            setNewStream(userVideoStream);
        });
    };

    const ownVideoCb = (peer) => (stream) => {
        setNewStream(stream);
        peer.on('call', (call) => {
            call.answer(stream);
            call.on('stream', (userVideoStream) => {
                setNewStream(userVideoStream);
            });
        });
        socket.on('user-joined', ({ userId }) => {
            console.log('joined!', { userId });
            connectToNewUser(userId, stream, peer);
        });
    }
    useEffect(() => {

        const peer = createPeerConnection();
        peer.on('open', (id) => {
            socket.emit('join', roomId, id);
        });

            navigator.mediaDevices.getUserMedia({
                video:true,
                audio:true
            }).then( ownVideoCb(peer));


    }, []);



    useEffect(() => {
        if(currentUser) {
            console.log('currentUser', currentUser);

            connectWithSocketServer(currentUser);

        }

    }, [currentUser]);


    useEffect(() => {
        if (!newStream) return;

        addVideoStream(newStream);
    }, [newStream]);

    return (

        <>
            <Container>

                <Navbar/>

                <div className="layout-wrapper d-lg-flex  d-flex justify-content-center avatars">
                    {streams.map((stream) => (
                        <Video key={stream.id} srcObject={stream} />
                    ))}

                </div>
                <h1 className="text-center my-2 pt-sm-2 d-none">Choisissez un salon</h1>
                <div className="layout-wrapper d-lg-flex  d-flex justify-content-center d-none">
                    <select name="room" id="" className="col-sm-3 col-md-2 my-2 d-none">
                        <option value="maths">Mathémathiques</option>
                    </select>
                </div>

            </Container>


        </>

    );
}

export default VideoChat;