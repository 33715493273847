import React, {useEffect, useRef} from 'react';
//import styles from "./Video.module.scss";

const Video = ({ srcObject,...props }) => {
    const refVideo = useRef(null)


    return (
        <div>
            <video className="{styles.video}" ref={refVideo} autoPlay {...props} />
        </div>
    );
};



export default Video;