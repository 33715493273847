import React, { useState } from 'react';
import IconButton from "@mui/material/IconButton";
import MicIcon from "@mui/icons-material/Mic";
import MicOffIcon from "@mui/icons-material/MicOff";


function Microphone() {
    const [microphoneEnabled, setMicrophoneEnabled] = useState(true);
    
    const handleToggleMicrophone = () => {
        setMicrophoneEnabled(!microphoneEnabled);
    }
    return (
        <IconButton onClick={handleToggleMicrophone} style={{ color: "white" }}>
    {microphoneEnabled ? <MicIcon /> : <MicOffIcon />}
</IconButton>
    );
}

export default Microphone;
