import axios from "axios";
import {addRoomRoute, getAllMessageRoute, getAllRoomRoute} from "../../utils/APIRoutes";

export const roomActions = {
    OPEN_ROOM: "ROOM.OPEN_ROOM",
    SET_ROOM_DETAILS: "ROOM.SET_ROOM_DETAILS",
    SET_ACTIVE_ROOMS: "ROOM.SET_ACTIVE_ROOMS",
    SET_LOCAL_STREAM: "ROOM.SET_LOCAL_STREAM",
    SET_REMOTE_STREAMS: "ROOM.SET_REMOTE_STREAMS",
    SET_AUDIO_ONLY: "ROOM.SET_AUDIO_ONLY",
    SET_SCREEN_SHARE_STREAM: "ROOM.SET_SCREEN_SHARE_STREAM",
    SET_IS_USER_JOINED_WITH_ONLY_AUDIO: "ROOM.SET_IS_USER_JOINED_WITH_ONLY_AUDIO",
};
export const ADD_ROOM = 'ADD_ROOM';
export const ADD_ROOM_SUCCESS = 'ADD_ROOM_SUCCESS';
export const ADD_ROOM_ERROR = 'ADD_ROOM_ERROR';
export const DEL_ROOM = 'DEL_ROOM';
export const FETCH_ROOM = 'FETCH_ROOM';
export const FETCH_ROOM_SUCCESS = 'FETCH_ROOM_SUCCESS';
export const FETCH_ROOM_ERROR = 'FETCH_ROOM_ERROR';

export const addRoom = (room) =>dispatch=> {

    /*const response = await axios.post(getAllMessageRoute, {
        from: currentUser._id,
        to: currentChat._id,
    });*/

    console.log('addRoom', room);

    dispatch({ type: ADD_ROOM });
    axios
        .post(addRoomRoute, room)
        .then((response) => {
            dispatch({ type: ADD_ROOM_SUCCESS, payload: response.data });
        })
        .catch((error) => {
            dispatch({ type: ADD_ROOM_ERROR, payload: error });
        });

    /*return {
        type: ADD_ROOM,
        payload: room
    }*/
}

export const fetchRooms = () => (dispatch) => {
    //dispatch({ type: FETCH_ROOM });
    axios
        .get(getAllRoomRoute)
        .then((response) => {
            console.log('fetchRooms', response.data);
            dispatch({ type: FETCH_ROOM, payload: response.data });
        })
        .catch((error) => {
            dispatch({ type: FETCH_ROOM_ERROR, payload: error });
        });

    /*return dispatch => {
        dispatch({
            type: FETCH_ROOM,
            payload: rooms
        })
    }*/
}


export const setOpenRoom = (
    isUserRoomCreator = false,
    isUserInRoom = false
) => {
    return {
        type: roomActions.OPEN_ROOM,
        isUserRoomCreator,
        isUserInRoom,
    };
};

export const getActions = (dispatch) => {
    return {
        setAudioOnly: (audioOnly) => dispatch(setAudioOnly(audioOnly)),
        setScreenSharingStream: (stream) => {
            dispatch(setScreenSharingStream(stream));
        },
    };
};

export const setRoomDetails = (roomDetails) => {
    return {
        type: roomActions.SET_ROOM_DETAILS,
        roomDetails,
    };
};

export const setActiveRooms = (activeRooms) => {
    return {
        type: roomActions.SET_ACTIVE_ROOMS,
        activeRooms,
    };
};

export const setLocalStream = (localStream) => {
    return {
        type: roomActions.SET_LOCAL_STREAM,
        localStream,
    };
};

export const setAudioOnly = (audioOnly) => {
    return {
        type: roomActions.SET_AUDIO_ONLY,
        audioOnly,
    };
};

export const setRemoteStreams = (remoteStreams) => {
    return {
        type: roomActions.SET_REMOTE_STREAMS,
        remoteStreams,
    };
};

export const setScreenSharingStream = (stream) => {
    return {
        type: roomActions.SET_SCREEN_SHARE_STREAM,
        isScreenSharingActive: stream ? true : false,
        screenSharingStream: stream || null,
    };
};

export const setIsUserJoinedOnlyWithAudio = (onlyWithAudio) => {
    return {
        type: roomActions.SET_IS_USER_JOINED_WITH_ONLY_AUDIO,
        isUserJoinedWithOnlyAudio: onlyWithAudio,
    };
};