import { Peer } from "peerjs";


const createPeerConnection = () => {
  return new Peer({
    path: '/peerjs/myapp',
    host: "/",
    port: 7777,
  });
}

export default createPeerConnection;
