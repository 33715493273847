import React, { useState, useEffect } from 'react';
import {Link, useNavigate} from "react-router-dom";
import { ToastContainer, toast} from "react-toastify";
import loader from "../assets/loader.gif";
import { Buffer } from "buffer";

import 'react-toastify/dist/ReactToastify.css';
import axios from "axios";
import {avatarRoute} from "../utils/APIRoutes";
import {Container} from "../style/RoomAndAvatarStyle";




function RoomAndAvatar(props) {

    const toastOptions = {
        position: "top-center",
        autoClose: 8000,
        pauseOnHover: true,
        draggable: true,
        theme: "light"
    }

    useEffect( () => {
        /*if (!localStorage.getItem('chat-app-user'))
            navigate("/login");
        else if  (localStorage.getItem('chat-app-user'))
            navigate("/");*/
    }, []);

    const setProfilePicture = async () => {

        if (selectedAvatar === undefined) {
            toast.error("Veuillez selectionner un avatar", toastOptions);
        } else {
            const user = await JSON.parse(
                localStorage.getItem('chat-app-user')
            );
            console.log('user', user.userId, user.email);
            console.log('route = ', `${avatarRoute}/${user._id}`);
            const { data } = await axios.post(`${avatarRoute}/${user._id}`, {
                image: avatars[selectedAvatar],
            });
            //isSet node builtIn function
            if (data.isSet) {
                user.isAvatarImageSet = true;
                user.avatarImage = data.image;
                localStorage.setItem('chat-app-user', JSON.stringify(user));
                navigate("/");
            }
            else {
                toast.error("Impossible de sélectionner un avatar. Veuillez reessayer.", toastOptions);
            }
        }

    }

    const api = `https://api.multiavatar.com/4645646`;
    const apikey = `SsIzDm8qw0v39e`;
    const navigate = useNavigate();
    const [avatars, setAvatars] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [selectedAvatar, setSelectedAvatar] = useState(undefined);

    useEffect( () => {
        async function fetchData() {
            const data = [];
            for (let i = 0; i < 10; i++) {
                const image = await axios.get(
                    `${api}/${Math.round(Math.random() * 1000)}?apikey=${apikey}`
                );
                const buffer = new Buffer(image.data);
                data.push(buffer.toString("base64"));
            }
            setAvatars(data);
            setIsLoading(false);
        }
        fetchData();
    }, []);

    return (
        <>
            {
                isLoading ? <Container>
                    <div className="layout-wrapper d-lg-flex d-flex justify-content-center">
                        <img src={loader} alt="loader" className="loader"/>
                    </div>
                </Container> : (

                    <Container>


                        <h1 className="text-center my-5 pt-sm-5">Choisissez un avatar</h1>
                        <div className="layout-wrapper d-lg-flex  d-flex justify-content-center avatars">

                            {
                                avatars.map((avatar, index) => {
                                    return (
                                        <div key={avatar}
                                            className={`avatar m-1 col-1 ${selectedAvatar === index ? "selected" : ""}`}>
                                            <img src={`data:image/svg+xml;base64,${avatar}`} alt="avatar" key={avatar}
                                                 className="img-fluid" onClick={() => setSelectedAvatar(index)}/>
                                            <div className={`avatar ${selectedAvatar === index ? "border border-primary" : ""}`}>
                                            </div>
                                        </div>
                                    )
                                })
                            }
                        </div>
                        <h1 className="text-center my-2 pt-sm-2 d-none">Choisissez un salon</h1>
                        <div className="layout-wrapper d-lg-flex  d-flex justify-content-center d-none">
                            <select name="room" id="" className="col-sm-3 col-md-2 my-2 d-none">
                                <option value="maths">Mathémathiques</option>
                            </select>
                        </div>
                        <div className="layout-wrapper d-lg-flex  d-flex justify-content-center">
                            <button onClick={setProfilePicture} className="btn btn-success m-2">Accéder au tchat </button>
                        </div>
                    </Container>
                )
            }

        </>
    );
}

export default RoomAndAvatar;