import { useAuth } from '../auth/auth';
import {Link, useNavigate} from "react-router-dom";
import React from "react"; // Import your auth service or library

function LogOutButton() {
    const navigate = useNavigate();
    const { signOut } = useAuth(); // Get the signOut function from the auth service

    const handleLogOut = () => {
        signOut() // Call the signOut function
            .then(() => {
                // Redirect the user to the login page or display a message indicating that they have logged out
                navigate('/login');
            })
            .catch((error) => {
                console.error(error); // Handle any errors
            });
    };

    return (


            <Link className="dropdown-item" onClick={handleLogOut}>Log out <i
                className="ri-logout-circle-r-line float-end text-muted"></i></Link>
        // <button className="btn btn-danger" onClick={handleLogOut}>Log Out</button> // Render the log out button
    );
}
export default LogOutButton;
