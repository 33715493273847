import './App.css';
import {BrowserRouter, Routes, Route} from "react-router-dom";
import Chat from "./pages/Chat";
import Register from "./pages/Register";
import Login from "./pages/Login";
import RoomAndAvatar from "./pages/RoomAndAvatar";
import VideoChat from "./pages/VideoChat";
import Room from "./pages/Room";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Chat/>}></Route>
        <Route path="/roomandavatar" element={<RoomAndAvatar/>}></Route>
        <Route path="/videochat" element={<VideoChat/>}></Route>
        <Route path="/room" element={<Room/>}></Route>
        <Route path="/register" element={<Register/>}></Route>
        <Route path="/login" element={<Login/>}></Route>
      </Routes>

    </BrowserRouter>
  );
}

export default App;
